import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { setToken, setLiffId } from '@/utils/auth'
import liff from '@line/liff';
import { setLog } from '@/api/log'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/404'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  
  // start progress bar
  NProgress.start()


  if (whiteList.indexOf(to.path) !== -1) {
    // in the free login whitelist, go directly
    next()
  }

  try {
    
    const href = window.location.href
    const host = window.location.hostname
    const path = window.location.pathname
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let redirectUri = 'https://' + host + path + queryString


    // const pt = urlParams.get('pt')
    // const gt = urlParams.get('gt')
    // const q = urlParams.get('q')
    // const rg = urlParams.get('rg')


    // if (pt && pt != '') {
    //   redirectUri += '?pt=' + pt
    // }
    // if (gt && gt != '') {
    //   redirectUri += '?gt=' + gt
    // }
    // if (q && q != '') {
    //   redirectUri += '?q=' + q
    // }
    // if (rg && rg != '') {
    //   redirectUri += '?rg=' + rg
    // }

    // 取網址第2個斜線以後為liffId
    const firstSlashIndex = href.indexOf('/', href.indexOf('//') + 2); // 找到主域名后的第一个斜杠
    const secondSlashIndex = href.indexOf('/', firstSlashIndex + 1);  // 找到第一个斜杠之后的第二个斜杠
    const thirdSlashIndex = href.indexOf('/', secondSlashIndex + 1);  // 找到第二个斜杠之后的第三个斜杠
    
    const segmentBetweenSecondAndThirdSlash = thirdSlashIndex !== -1 
      ? href.substring(secondSlashIndex + 1, thirdSlashIndex) 
      : href.substring(secondSlashIndex + 1);

      const cleanSegment = segmentBetweenSecondAndThirdSlash.includes('?') ? segmentBetweenSecondAndThirdSlash.substring(0, segmentBetweenSecondAndThirdSlash.indexOf('?')) : segmentBetweenSecondAndThirdSlash;
      const finalSegment = cleanSegment.includes('#') ? cleanSegment.substring(0, cleanSegment.indexOf('#')) : cleanSegment;
      
    const pattern = /^\d+-[A-Za-z0-9]+$/;

    let liffId;
    if (pattern.test(finalSegment)) {
      liffId = finalSegment
    } else {
      alert('缺少liffId')
      next('/404')
      NProgress.done()
    }

    
    liff
    .init({
      liffId: liffId,
    })
    .then(() => {
      // LIFF 初始化成功
      if (!liff.isLoggedIn()) {
        liff.login({ redirectUri: redirectUri });
      }
      const idToken = liff.getIDToken();
      if (idToken) {
        console.log(idToken)
        setToken(idToken)
        setLiffId(liffId)
        next()
    
      } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
          // in the free login whitelist, go directly
          next()
        } 
      }
    })
    .catch((error) => {
      // LIFF 初始化失敗
      console.error('LIFF initialization failed', error);
    });

    // determine whether the user has logged in

  } catch (error) {

      let log = {
        type: 'Error', 
        content: error.message
      }
      await setLog(log)
  }
 
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})