<template>

    <div class="game-container" v-loading="loading">

      <game-prize-modal :game-prize="gamePrize" :share="data.share" :do-share="doShare" :friend-url="data.friendUrl"></game-prize-modal>
      <game-fail-modal :content-type="contentType" :binding-url="data.bindingUrl"></game-fail-modal>

      <button type="button" id="fail_btn" data-bs-target="#gameFailModal" data-bs-toggle="modal" hidden></button>
      
      <template v-if="data.status == '未開始'">
        <div class="game-error text-center">
            <img src="https://storage.googleapis.com/c360plus/game/game_over.png" alt="遊戲已結束">
            <div class="game-error-content text-grey-1">
                <span class="text-bold text-size-20">活動尚未開始</span><br/>
                <span class="text-size-16">敬請期待</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '已結束'">
        <div class="game-error text-center">
            <img src="https://storage.googleapis.com/c360plus/game/game_over.png" alt="遊戲已結束">
            <div class="game-error-content text-grey-1">
                <span class="text-bold text-size-20">活動已結束</span><br/>
                <span class="text-size-16">敬請期待我們的下一個活動</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '無權限'">
        <div class="game-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/failed.png" alt="無法開啟此遊戲">
            <div class="game-error-content text-grey-1">
                <span class="text-bold text-size-20">Sorry</span><br/>
                <span class="text-size-16">無法開啟此遊戲</span>
            </div>
        </div>
      </template>

      <template v-else-if="data.status == '外部瀏覽器'">
        <div class="game-error text-center">
            <img src="https://storage.googleapis.com/c360plus/prize/failed.png" alt="無法開啟此遊戲">
            <div class="game-error-content text-grey-1">
                <span class="text-bold text-size-20">Sorry</span><br/>
                <span class="text-size-16">請使用 LINE 瀏覽器開啟</span>
            </div>
        </div>
      </template>

      <template v-else>
        <div :style="[gameStyle.backgroundColorType == 'default' ? {'background-color': getDefaultBgc(gameStyle.mainColor)} : {'background-color': gameStyle.backgroundColor}]">
            <div class="game-content">
                <div class="game-content-fix">

                    <div class="game-content-img">

                    
                    
                    <img :style="[gameStyle.backgroundImagePath && gameStyle.backgroundImagePath != '' ? {'visibility': 'hidden'} : {}]" :src="getBackgroundImage()" alt="遊戲背景圖">
                    <img v-if="gameStyle.backgroundImagePath && gameStyle.backgroundImagePath != ''" :src="gameStyle.backgroundImagePath" alt="遊戲背景圖" style="position: absolute;">
                    <game-anime :is-drawing="isDrawing" :main-color="gameStyle.mainColor"></game-anime>
                    

                    <div v-show="data.share" @click="doShare()" class="game-content-share text-size-14">
                        <label style="transform: rotateY(180deg);" ><i class='bx bx-share'></i></label>&nbsp;分享遊戲
                    </div>
                    
                    
                    <!-- <img v-show="gameStyle.mainColor == '活潑'" src="https://storage.googleapis.com/c360plus/game/game1_item_1.png" alt="遊戲籤筒">
                    <img v-show="gameStyle.mainColor == '中性'" src="https://storage.googleapis.com/c360plus/game/game1_item_2.png" alt="遊戲籤筒">
                    <img v-show="gameStyle.mainColor == '喜慶'" src="https://storage.googleapis.com/c360plus/game/game1_item_3.png" alt="遊戲籤筒"> -->
                    </div>
        
                    <div class="game-content-btn" :style="[{'background-color': getDefaultBtnStyle(gameStyle.mainColor)['background-color']}]">
                        <button type="button" class="text-size-22 text-bold" :style="getDefaultBtnStyle(gameStyle.mainColor)" @click="doDraw" :disabled="isDrawing || !isQualified">{{gameStyle.buttonName}}</button>
                        <button id="prize_btn" data-bs-target="#gamePrizeModal" data-bs-toggle="modal" hidden></button>
                    </div>
                </div>
            </div>

            <div style="padding: 20px;">
                <div class="game-content-rule">
                    <div class="game-content-rule-block">
                        <template v-if="data.frequency == '每人'">
                            <div class="text-center text-size-14 text-bold">
                                剩餘遊戲次數：{{data.remainTimes ? data.remainTimes : 0}}次
                            </div>
                        </template>
                        <template v-else-if="data.frequency == '每日'">
                            <div class="text-center text-size-14 text-bold">
                                今日剩餘遊戲次數：{{data.remainTimes ? data.remainTimes : 0}}次
                            </div>
                            <div class="text-center text-size-14 text-grey-3">
                                每日 {{data.dailyResetAt}} 重新計算遊戲次數
                            </div>
                        </template>
                    </div>

                    <div class="game-content-rule-block">
                        <div class="text-center text-size-14 text-bold">
                            遊戲活動期間
                        </div>
                        <div class="text-center text-size-14">
                            {{data.startAt.replace(/:00$/, '')}} 至 {{data.endAt.replace(/:00$/, '')}}
                        </div>
                    </div>

                    <div class="game-content-rule-block">
                        <template v-if="gameStyle.rule && gameStyle.rule != ''">
                            <div class="text-center text-size-14 text-bold">規則說明</div>
                            {{gameStyle.rule}}
                        </template>
                        
                        <template v-if="gameStyle.prizeImagePath">
                            <div style="width: 100%; text-align: center;margin-top: 20px;">
                                <img :src="gameStyle.prizeImagePath" alt="獎項圖">
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </template>

    </div>

</template>
<script>
import $ from 'jquery'
import { getLineToken, getLiffId } from '@/utils/auth'
import GameAnime from '@/components/game/anime.vue'
import GamePrizeModal from '@/components/modal/game/GamePrizeModal.vue'
import GameFailModal from '@/components/modal/game/GameFailModal.vue'
import { getGame, doShareGame, doPlayGame } from "@/api/game";
import { getMember } from "@/api/member";  
import { setLog } from '@/api/log'
import liff from '@line/liff';

import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Taipei')

export default {
    metaInfo() {
        return {
            title: this.pageTitle,
        };
    },
    components: {
        GameAnime,
        GamePrizeModal,
        GameFailModal
    },
    computed: {
        getBackgroundImage: function() {
            return () => {
                
                if (this.gameStyle.mainColor) {
                    switch (this.gameStyle.mainColor) {
                        case '活潑':
                            return 'https://storage.googleapis.com/c360plus/game/game1_bg_1.png'
                        case '中性':
                            return 'https://storage.googleapis.com/c360plus/game/game1_bg_2.png'
                        case '喜慶':
                            return 'https://storage.googleapis.com/c360plus/game/game1_bg_3.png'
                    }
                }
            }
        },
        getDefaultBgc: function() {
            return (value) => {
                let backgroundColor = '';
                if (value == '活潑') {
                backgroundColor = '#F2BAC9'
                } else if (value == '中性') {
                backgroundColor = '#B8DCEC'
                } else if (value == '喜慶') {
                backgroundColor = '#F2ECD4'
                } 
                return backgroundColor
            }
        },
        getDefaultBtnStyle: function() {
            return (value) => {
                if (!value) {
                    return
                }
                let nameColor;
                let borderColor;
                let backgroundColor;
                if (value == '活潑') {
                    nameColor = '#FFFDDC'
                    borderColor = '#FFFDDC'
                    backgroundColor = '#FD4640'
                } else if (value == '中性') {
                    nameColor = '#FFFFFF'
                    borderColor = '#FFFFFF'
                    backgroundColor = '#6996FE'
                } else if (value == '喜慶') {
                    nameColor = '#915403'
                    borderColor = '#FFFDDC'
                    backgroundColor = '#FFD45D'
                } 
                return {
                    'color': nameColor,
                    'border': '2px solid ' + borderColor,
                    'background-color': backgroundColor
                }
            }
        },
        },
    data() {
            return {
                pageTitle: '',
                liffId: '',
                idToken: '',
                jwtToken: '',
                qToken: '',
                loading: false,
                isBinding: false,
                isQualified: false,
                gamePrize: {},
                data: {
                    status: ''
                },
                gameStyle: {
                    // mainColor: '活潑',
                    // buttonName: '開始抽獎',
                    // backgroundColorType: 'default',
                    // backgroundColor: null,
                    // backgroundFileName: '',
                    // backgroundFileData: null,
                    // backgroundImagePath: 'https://storage.googleapis.com/c360plus/game/game1_bg_1.png',
                    // backgroundFileError: false,
                    // prizeFileName: '',
                    // prizeFileData: null,
                    // prizeImagePath: '',
                    // prizeFileError: false,
                    // rule: '1.參加者點擊進入遊戲畫面時，即同意接受本活動之各項規範；如有任何違反本活動注意事項、破壞本活動、涉及不當得利或違法之行為，主辦單位得取消其參加或中獎資格，並對其請求損害賠償。'
                },
                isDrawing: false,
                contentType: ''
            }
        },
        created() {

            try {
                
                this.liffId = getLiffId()
                this.idToken = getLineToken()
                
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const gt = urlParams.get('gt')
                const q = urlParams.get('q')
                this.jwtToken = gt
                this.qToken = q

                if ((!this.jwtToken || this.jwtToken == '') && (!this.qToken || this.qToken == '')) {
                    this.data.status = '無權限'
                    return
                }
                console.log('liff.isInClient : ', liff.isInClient())
                if (!liff.isInClient()) {
                    this.data.status = '外部瀏覽器'
                    return
                }
                
                this.fetchData() 
            } catch (error) {
                let log = {
                    type: 'Error', 
                    content: error.message
                }
                setLog(log)
            }
        },
        mounted() {
            
        },
        methods: {
            async fetchData() {
                let data = {
                    lineToken: this.idToken,
                }

                if (this.jwtToken != '') {
                    data.jwtToken = this.jwtToken
                } 
                if (this.qToken != '') {
                    data.q = this.qToken
                }

                try {
                    let res = await getGame(this.liffId, data)
                    console.log('res ===', res)
                    if (!res.isSuccess) {
                        this.data.status = '無權限'
                        return
                    }
                    if (res.gameStyle) {
                        this.gameStyle = JSON.parse(res.gameStyle)
                    }
                    if (res.data) {
                        this.data = res.data
                        if (this.data.jwtToken) {
                            this.jwtToken = this.data.jwtToken
                        }


                        // 沒關注就直接導到關注
                        if (!this.data.isFollow && this.data.friendUrl) {
                            window.location.href = this.data.friendUrl
                            return
                        }
                        

                        if (this.data.lineAccountName) {
                            this.pageTitle = this.data.lineAccountName
                        }
                    }

                    // 判斷是否要綁定才能玩遊戲
                    this.$nextTick(() => {
                        if (this.data.needToBind && this.data.status == "進行中") {
                            this.contentType = 'noBinding'
                            $('#fail_btn').click()
                            return
                        }
                        this.isQualified = true
                    });
            
                    
                
                } catch (error) {
                    this.data.status = '無權限'
                    return error
                }
                
            },
            doShare() {
                
                // 判斷是否用LINE內部瀏覽器開啟
                let isInClient = liff.isInClient();
                if (!isInClient) {
                    this.contentType = 'lineBrowserError'
                    $('#fail_btn').click()
                    return
                }

                let token = ''
                if (this.jwtToken != '') {
                    token = this.jwtToken
                } else if (this.qToken != '') {
                    token = this.qToken
                }

                let data = {
                    jwtToken: token
                }

                doShareGame(data)
                let shareContent = this.data.shareContent
                shareContent = encodeURI(this.data.shareContent)
                shareContent = shareContent.replace('?q=', '?q%3D')
                window.location.replace('https://line.me/R/share?text=' + shareContent)
                
                
            },
            async doDraw() {

                if (!this.isQualified) {
                    return
                }
                if (this.data.frequency == '每人') {
                    this.contentType = 'noLotteryTimes'
                } else {
                    this.contentType = 'noTodayLotteryTimes'
                }

                if (this.data.remainTimes == 0) {
                    $('#fail_btn').click()
                    return
                }

                console.log('startToDraw')
                this.isDrawing = true


                let data = {
                    lineToken: this.idToken,
                    jwtToken: this.jwtToken
                }

                
   
                try {
                    let res = await doPlayGame(this.liffId, data)
                    if (res.isSuccess) {
                        setTimeout(() => {
                            this.gamePrize = res
                            this.isDrawing = false
                            this.data.remainTimes--
                            $('#prize_btn').click()
                        }, 800);
                        return
                    } else if (res.msg == '抽獎次數已使用完畢') {
                        this.isDrawing = false
                        $('#fail_btn').click()
                    } else {
                        this.isDrawing = false
                        this.contentType = 'internalError'
                        $('#fail_btn').click()
                    }
                } catch (error) {
                    this.isDrawing = false
                    this.contentType = 'internalError'
                    $('#fail_btn').click()
                    return error
                }

                
            }
        },
}
</script>

<style scoped>

.game-container {
    max-width: 768px;
    margin: auto;
    position: relative;
}

.game-error img {
    width: 90px;
    margin-top: 150px;
    margin-bottom: 40px;
}

.game-content {
    z-index: 1;
}

.game-content-img {
    display: grid;
    position: relative;
}

.game-content-img img {
    grid-area: 1 / 1; /* 所有图片放在同一个网格区域 */
    width: 100%;
    height: auto;
}

.game-content-btn {
    position: relative;
    z-index: 50;
    width: 50%; 
    padding: 6px;
    border-radius: 50px;
    border: unset;
    margin: -20% auto 0 auto;
}

.game-content-btn button {
    width: 100%;
    padding: 4px 0;
    text-align: center;
    border-radius: 50px;
}

.game-content-share {
    position: absolute;
    right: 14px;
    border-radius: 38px;
    padding: 4px 16px;
    opacity: 0.5;
    background-color: #000;
    color: #fff;
    top: min(max(36vw, 0px), 250px);
    font-size: min(max(3.5vw, 16px), 40px);
}

.game-content-rule {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    padding: 20px;
    position: relative;
    z-index: 49;
    /* white-space: pre-wrap; */
}

.game-content-rule-block {
    margin: 10px 0;
}

.game-content-rule img {
    width: 100%;
    text-align: center;
}
</style>